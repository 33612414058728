import { useContext } from 'react'
import fetch from 'isomorphic-unfetch'
import serializeObject from '@arsenal/arsenal/modules/serializeObject'
import getUrl from 'lib/url'
const apiMap = {
  1: '/ugc/video/v1/digg/digg/',
  4: '/motor/discuss_ugc/digg/digg/',
}

const methodMap = {
  1: 'POST',
  4: 'POST',
}

interface FocusParams {
  user_id: number
  to_user_id: number
}

interface DiggParams {
  user_id: number
  group_id: string
  action: 'digg' | 'cancel_digg'
  like_type?: 'digg_thread' | 'digg_ugc_video'
}

interface DiggCommentParams {
  comment_id: string
  group_id: string
}

export const focus = ({ user_id, to_user_id }: FocusParams) => {
  const params = {
    user_id,
    to_user_id,
  }
  return fetch('/motor/user/relation/like', {
    method: 'POST',
    headers: {
      'tt-anti-token': window.localStorage.getItem('tt-anti-token') || '',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: serializeObject(params),
  })
    .then(res => res.json())
    .then(res => {
      const { message, is_following } = res.data
      if (message === 'success' && is_following) {
        return Promise.resolve()
      } else {
        return Promise.reject('关注失败')
      }
    })
    .catch(err => {
      console.log(err)
      return Promise.reject('关注失败')
    })
}

export const digg = ({
  user_id,
  group_id,
  action,
  like_type = 'digg_thread',
}: DiggParams) => {
  const params = {
    user_id,
    thread_id: group_id,
    digg_action: action,
    like_type,
    group_id,
    // device_id: 0,
    // channel: 'local_test',
  }
  let request = null
  // if (ugcType === 1 || ugcType === 4) {
  //   request = fetch(apiMap[ugcType], {
  //     method: methodMap[ugcType],
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body: serializeObject(params),
  //   })
  // } else {
  //   request = fetch(
  //     `/ttdiscuss/v1/commit/threaddigg/?${serializeObject(params)}`
  //   )

  // }
  request = fetch(getUrl(`/motor/community/digg_like`), {
    method: 'post',
    headers: {
      'tt-anti-token': window.localStorage.getItem('tt-anti-token') || '',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: serializeObject(params),
  })
  return request
    .then(res => res.json())
    .then(res => {
      const { err_no, err_tips } = res
      if (err_no) {
        return Promise.reject(err_tips)
      } else {
        return Promise.resolve()
      }
    })
    .catch(err => {
      console.log(err)
      return Promise.reject('点赞失败')
    })
}

export const diggComment = ({ comment_id, group_id }: DiggCommentParams) => {
  const params = {
    aid: 36,
    user_id: 64194007718,
    comment_id,
    group_id,
    device_id: 69983831301,
    aggr_type: 0,
    action: 'digg',
  }
  return fetch('/2/data/comment_action/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: serializeObject(params),
  })
    .then(res => res.json())
    .then(res => {
      const { digg_count } = res.data
      if (digg_count === 1) {
        return Promise.resolve()
      } else {
        return Promise.reject('点赞失败')
      }
    })
    .catch(err => {
      console.log(err)
      return Promise.reject('点赞失败')
    })
}
