/* 声明文件 */
import React from 'react'

export default function Statement() {
  const year = new Date().getFullYear()
  return (
    <div className="statement">
      <div className="content">
        <p>
          © {year} 懂车帝 www.dongchedi.com{' '}
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802026035"
            target="_blank"
            rel="noopener noreferrer"
          >
            京公网安备 11010802026035号{' '}
          </a>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            京ICP备17027026号-3{' '}
          </a>
          <a
            href="http://sf1-ttcdn-tos.pstatp.com/obj/ttfe/motor/pc/ICP.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            增值电信业务经营许可证（京B2-20180202）
          </a>
          <a
            href="http://sf1-ttcdn-tos.pstatp.com/obj/ttfe/motor/pc/radio-tv-License.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            广播电视节目制作经营许可证
          </a>
        </p>
        <p>
          公司名称：北京空间变换科技有限公司{' '}
          <a
            href="http://www.12377.cn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            中央网信办违法和不良信息举报中心{' '}
          </a>
          违法和不良信息举报电话：400-140-2108
        </p>
        <p>
          <a
            href="http://www.dongchediapp.com/cloud/page/agreement.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            懂车帝用户协议
          </a>{' '}
          |{' '}
          <a
            href="http://www.dongchediapp.com/cloud/page/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            懂车帝隐私政策
          </a>{' '}
          |{' '}
          <a
            href="http://sf1-ttcdn-tos.pstatp.com/obj/ttfe/motor/pc/business-License.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            营业执照
          </a>
        </p>
        <p>
          <a
            className="strong"
            href="https://www.toutiao.com/complain/"
            target="_blank"
            rel="noopener noreferrer"
          >
            侵权投诉
          </a>{' '}
          |{' '}
          <span
            className="strong"
            onClick={() => {
              if ('dpFeedback' in window) {
                window.dpFeedback.start()
              }
            }}
          >
            我要反馈
          </span>
        </p>
      </div>
      <style jsx>
        {`
          .statement {
            background-color: #f8f8f8;
          }
          .content {
            width: 948px;
            line-height: 20px;
            margin: 0 auto;
            padding: 20px 0;
            font-size: 12px;
            color: #666;
          }
          p {
            text-align: center;
          }
          a:hover,
          span:hover {
            cursor: pointer;
            color: #406599;
          }
          a.strong {
            font-weight: bold;
          }
          span.strong {
            font-weight: bold;
          }
        `}
      </style>
    </div>
  )
}
