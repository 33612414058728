import React, { useState, useEffect, useCallback, CSSProperties } from 'react'
import LiveSwiper from './components/live-swiper'
import Followees from './components/followees'
import serializeObject from '@arsenal/arsenal/modules/serializeObject'
import Cookies from '@arsenal/arsenal/modules/Cookies'
import Footer from 'Components/footer-statement'

import Celebrity, {
  CelebrityMonth,
  CelebrityWeek,
} from './components/celebrity'
import LiveShows, {
  LiveData,
  IBannerList,
  mapShownData,
} from './components/live-show'
import getUrl from 'lib/url'

interface IBannerProps {
  bannerData: IBannerList[]
  loaded: 0 | 1 | 2
}

const BANNER_TYPE = 5256
const SECOND_TYPE = 5257
const LIVE_TYPE = 5258
const LIMIT = 5

// 获取banner位数据
const getBannerData = (allInfo: LiveData[]) => {
  const bannerData: IBannerList[] = []
  allInfo
    .filter(e => e?.type === BANNER_TYPE)
    .forEach(e => {
      const list = e?.card_content?.list || []
      list.forEach(e => {
        if (/livechat/.test(e?.open_url || '')) {
          //const result = (e?.open_url || '').match(/room_id=(\d+)/)
          bannerData.push({
            open_url: e?.open_url,
            img_url: e?.img_url,
            title: e?.title,
            gid: e?.gid,
          })
        }
      })
    })
  if (bannerData.length > 0) {
    return bannerData
  }
  return (
    allInfo
      ?.filter(e => e?.type === LIVE_TYPE)
      ?.slice(0, 3)
      .map(e => {
        return {
          open_url: e?.share_info?.share_url,
          img_url: e?.image_list?.[0]?.url,
          title: e?.share_info?.title,
          gid: e?.unique_id_str,
        }
      }) || []
  )
}

export const renderTitle = (
  noData = false,
  title: string | undefined,
  loaded = 0,
  style: CSSProperties = {},
  defaultStyle: CSSProperties = {}
) => {
  const default_style = noData ? defaultStyle : {}
  return (
    <p style={{ ...style, ...default_style }}>
      {!noData
        ? title
        : loaded === 1
          ? '本时段暂无直播，可以逛逛其他内容哦~'
          : loaded === 0
            ? '直播加载中'
            : '网络异常，建议刷新页面重试哦～'}
    </p>
  )
}

const LiveBannerWrapper = (props: IBannerProps) => {
  const { bannerData = [], loaded = 0 } = props
  const [slideIndex, setSlideIndex] = useState(0)
  return (
    <div className="live-banner-wrapper">
      <a
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        rel="unsafe-url"
        href={`https://live.ixigua.com/room/${
          bannerData.length !== 0 ? bannerData[slideIndex].gid : ''
        }`}
      >
        <LiveSwiper bannerData={bannerData} setSlideIndex={setSlideIndex} />
      </a>

      <div className="banner-bottom">
        {/* <p className={bannerData.length !== 0 ? '' : 'default'}>
          {bannerData.length !== 0
            ? bannerData[slideIndex].title
            : loaded === 1
              ? '本时段暂无直播，可以逛逛其他内容哦~'
              : loaded === 0
                ? '直播加载中'
                : '网络异常，建议刷新页面重试哦～'}
        </p> */}
        {renderTitle(
          bannerData.length === 0,
          bannerData[slideIndex]?.title,
          loaded,
          {
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '24px',
          },
          {
            color: '#979aa8',
            textAlign: 'center',
            fontSize: '16px',
            width: '100%',
          }
        )}
        {bannerData.length !== 0 && (
          <a
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="unsafe-url"
            href={`https://live.ixigua.com/room/${bannerData.length !== 0 &&
              bannerData[slideIndex].gid}`}
          >
            <button className="enter-live-btn" type="button">
              进入直播间
            </button>
          </a>
        )}
      </div>

      <style jsx>
        {`
          .live-banner-wrapper {
            position: relative;
            display: inline-block;
            width: 800px;
            height: 389px;
            border-radius: 2px;
            vertical-align: top;
            overflow: hidden;
          }

          .banner-bottom{
            position: absolute;
            z-index: 2;
            bottom: 0;
            display: inline-block;
            height: 64px;
            width: 100%;
            color: white;
            display: flex;
            justify-content: space-between;
            align-items center;
            padding-left: 24px;
            padding-right: 24px;
            background-color: #1f2129;
          }
          .enter-live-btn{
            position: absolute;
            padding: 0 24px;
            right: 20px;
            top: 20px;
            border: 0;
            outline: 0;
            height: 32px;
            background-color: #ffcd32;
            border-radius: 2px;
            text-align: center;
            color: #1f2129;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
          }

        `}
      </style>
    </div>
  )
}

interface IProps {
  allInfo: LiveData[]
  weeklyData: CelebrityWeek[]
  monthlyData: CelebrityMonth[]
}

function LiveHome({ weeklyData, monthlyData }: IProps) {
  const [bannerData, setBannerData] = useState<IBannerList[]>([])
  const [allInfo, setAllInfo] = useState<LiveData[]>([])
  const [loaded, setLoaded] = useState<0 | 1 | 2>(0) // 0未加载 1已加载 2加载失败

  useEffect(() => {
    const web_id = Cookies('tt_webid') || ''
    const defaultParams = {
      tt_from: 'pull',
      category: 'motor_car_live',
      refer: 1,
      count: 14,
      impression_info: JSON.stringify({
        page_id: 'page_category',
        sub_tab: 'motor_car_live',
      }),
      motor_feed_extra_params: JSON.stringify({
        new_feed: true,
        feed_type: 3,
        refresh_num: 0,
        loadmore_num: 0,
        from_page_id: 'page_category',
        live_sub_tab: 'all',
      }),
      max_behot_time: 0,
      version_code: 600,
      channel: 'web',
      device_platform: 'web',
      aid: 1839,
      user_id: web_id,
    }

    fetch(
      getUrl(
        `/motor/stream/api/news/feed/web/v46/?${serializeObject(defaultParams)}`
      )
    )
      .then(res => res.json())
      .then(json => {
        const result = json.data.map(mapShownData)
        setAllInfo(result)
        setLoaded(1)
      })
      .catch(e => {
        setLoaded(2)
        console.error('初次加载全部直播失败', e)
      })
  }, [])

  // 切换直播主页背景色
  useEffect(() => {
    const color = document.body.style.backgroundColor
    document.body.style.backgroundColor = '#f7f8fc'
    return () => {
      document.body.style.backgroundColor = color
    }
  }, [])

  // 当依赖变化时，更新banner位数据
  useEffect(() => {
    setBannerData(getBannerData(allInfo))
  }, [allInfo])

  // 通过useback传入数据 避免浏览器页面切换时 触发组件重渲染 prop diff为true
  const getLiveData = useCallback(
    () =>
      allInfo.filter(
        e =>
          !bannerData.find(bd => bd.gid === e.unique_id_str) &&
          e.type === LIVE_TYPE
      ),
    [allInfo, bannerData]
  )

  return (
    <div className="live-home">
      <LiveBannerWrapper bannerData={bannerData} loaded={loaded} />
      <div className="followees-wrapper">
        <Followees />
      </div>
      <div className="live-celebrity">
        <Celebrity wD={weeklyData} mD={monthlyData} />
      </div>
      <div className="live-shows">
        <LiveShows getLiveData={getLiveData} />
      </div>
      <div className="copyright">
        <Footer />
      </div>

      <style jsx>
        {`
          .live-home {
            width: 1196px;
            margin: 16px auto 0;
            box-sizing: content-box;
          }

          .followees-wrapper {
            margin-left: 16px;
            display: inline-block;
            width: 380px;
            height: 389px;
          }
          .live-celebrity {
            padding-top: 42px;
            width: 100%;
            height: 284px;
            box-sizing: content-box;
          }
          .live-shows {
            padding-top: 40px;
            width: 100%;
          }

          .copyright {
            text-align: center;
            margin-top: 120px;
            margin-bottom: 40px;
            font-size: 12px;
            line-height: 20px;
            color: #606370;
          }
        `}
      </style>
    </div>
  )
}

LiveHome.getInitialProps = async () => {
  // 请求Bee周榜
  const getWeeklyData = async () => {
    const json = await fetch(
      'https://sf1-hscdn-tos.pstatp.com/obj/ies-fe-bee/bee_prod/biz_377/bee_prod_377_bee_publish_2512.json'
    )
      .then(res => res.json())
      .then(json => {
        return json
      })
      .catch(e => {
        console.error(e)
        return []
      })
    return json
  }

  // 请求Bee月榜
  const getMonthlyData = async () => {
    const json = await fetch(
      'https://sf1-hscdn-tos.pstatp.com/obj/ies-fe-bee/bee_prod/biz_377/bee_prod_377_bee_publish_2513.json'
    )
      .then(res => res.json())
      .then(json => {
        return json
      })
      .catch(e => {
        console.error(e)
        return []
      })
    return json
  }

  const data = await Promise.all([getWeeklyData(), getMonthlyData()])
  return {
    weeklyData: data[0] || [],
    monthlyData: data[1] || [],
  }
}

export default LiveHome
