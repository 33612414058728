/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, SyntheticEvent, useContext } from 'react'
import get from 'lodash/get'
import { focus } from 'lib/ugc-interact'
import { getLoginHref } from 'lib/get-login-href'
import { GlobalStoreContext } from 'Components/store'
import { sendTeaCommEvent } from 'lib/tea-analyze'
import formatNumber from '@arsenal/arsenal/modules/formatNumber'
import getUrl from 'lib/url'
import Loading from 'Components/loading'
import AvatarStyle from 'Constants/global-styles/avatar-style'
export interface FolloweeData {
  room_id: string
  popularity: number
  title: string
  create_time: number
  finish_time: number
  cover_url: string
  user_info: {
    user_id_str: string
    user_name: string
    open_url: string
    avatar: string
  }
  fans_num: number
  subscribed?: boolean
}

const empty_pic = require('static/empty-pic@3x.png')
const error_pic = require('static/error-pic@3x.jpg')

function MineCard(props: FolloweeData) {
  const { popularity = 0, title, user_info, room_id, cover_url } = props
  return (
    <div
      className="mine-card"
      onClick={e => {
        sendTeaCommEvent('click_home_live_follow_live_card', {
          sub_tab: '我的关注',
          anchor_id: user_info.user_id_str,
          room_id,
        })
      }}
    >
      <a
        target="_blank"
        rel="unsafe-url"
        href={`https://live.ixigua.com/room/${room_id}`}
      >
        <div className="cover-wrapper">
          <div className="live-box">
            <span className="live-box-left">直播中</span>
            <span className="live-box-right">
              <span>{formatNumber(popularity)}人在看</span>
            </span>
          </div>
        </div>
      </a>

      <div className="info">
        <span className="title">
          <a
            target="_blank"
            rel="unsafe-url"
            href={`https://live.ixigua.com/room/${room_id}`}
          >
            {title}
          </a>
        </span>

        <a
          target="_blank"
          rel="unsafe-url"
          href={`https://live.ixigua.com/room/${room_id}`}
        >
          <span
            className="author-info"
            onClick={e => {
              sendTeaCommEvent('click_home_live_follow_profile', {
                sub_tab: '我的关注',
                user_id: user_info.user_id_str,
              })
            }}
          >
            <span className="avatar" />
            <span className="name">{user_info.user_name}</span>
          </span>
        </a>
      </div>
      <style jsx>
        {`
        .mine-card{
          padding: 8px;
          width: 100%;
          height: 88px;
          margin-bottom: 8px;
          border-radius: 2px;
          background-color: white;
          display: flex;
          align-items: center;
        }

        .cover-wrapper{
          position: relative;
          width: 126px;
          height: 72px;
          border-radius: 2px;
          background-image: url("${cover_url}");
          background-position : center;
          background-size: cover;
          bakcgorund-repeat: no-repeat;
          margin-right: 8px;
          flex: 0 0 auto;
        }

        .live-box {
          position: absolute;
          height: 24px;
          z-index: 1;
          top: 0;
          left: 0;
        }

        .live-box-left {
          display: inline-block;
          padding: 4px;
          height: 24px;
          border-radius: 2px 0 0 2px;
          background-color: #ffcc32;
          font-size: 10px;
          font-weight: 500;
          line-height: 1.33;
          color: #1f2129;
        }

        .live-box-right {
          position: relative;
          display: inline-block;
          padding: 6px 4px;
          height: 24px;
          border-radius: 0 2px 2px 0;

          font-size: 10px;
          font-weight: 500;
          line-height: 1;
          color: white;
        }
        .live-box-right > span {
          position: relative;
        }
        .live-box-right::before {
          left: 0;
          top: 0;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
        }

        .info{
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .title{
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 500;
          color: #1f2129;
        }

        .author-info{
          display: flex;
          align-items: center;
        }

        .avatar{
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-image: url("${user_info.avatar}");
          background-position : center;
          background-size: cover;
          bakcgorund-repeat: no-repeat;
          background-color: rgba(0, 0, 0, 0.08);
          margin-right: 4px;
        }
        .name{
          font-size: 12px;
          line-height: 18px;
          color: #979aa8;
        }
        `}
      </style>
    </div>
  )
}

const follow = (
  subscribed: boolean,
  user_id_str: string,
  onFollow: (x: string, b: boolean) => void,
  globalState: { userData?: { user_id?: string } }
) => {
  if (subscribed) return
  sendTeaCommEvent('click_home_live_follow_user', {
    sub_tab: '推荐直播间',
    user_id: user_id_str,
  })
  const my_user_id = get(globalState, 'userData.user_id')
  if (!my_user_id) {
    window.location.href = getLoginHref()
    return
  }
  // 先假设关注成功
  onFollow(user_id_str, true)
  focus({
    user_id: Number(my_user_id),
    to_user_id: Number(user_id_str),
  }).catch(() => {
    // 如果失败了，重置关注状态
    onFollow(user_id_str, false)
  })
}

function Card(props: {
  data: FolloweeData
  onFollow: (x: string, b: boolean) => void
}) {
  const {
    data: { fans_num = 0, title, user_info, room_id, subscribed = false },
    onFollow,
  } = props
  const { state: globalState } = useContext(GlobalStoreContext)
  const { className: avatar_class, styles: avatar_styles } = AvatarStyle
  return (
    <div className="card">
      <div
        className="avatar"
        onClick={e => {
          sendTeaCommEvent('click_home_live_follow_profile', {
            sub_tab: '推荐直播间',
            user_id: user_info.user_id_str,
          })
        }}
      >
        <a
          target="_blank"
          rel="unsafe-url"
          href={`https://live.ixigua.com/room/${room_id}`}
        >
          <span className={`inner ${avatar_class}`} />
          <span className="label">
            <span className="for-chrome">直播中</span>
          </span>
        </a>
      </div>

      <span className="title">
        <a
          target="_blank"
          rel="unsafe-url"
          href={`https://live.ixigua.com/room/${room_id}`}
        >
          {title}
        </a>
      </span>
      <span className="follower-num">
        <a
          target="_blank"
          rel="unsafe-url"
          href={`https://live.ixigua.com/room/${room_id}`}
        >
          {formatNumber(fans_num)}粉丝
        </a>
      </span>

      <button
        className={`follow-btn ${subscribed ? 'followed' : ''}`}
        onClick={() => {
          follow(subscribed, user_info.user_id_str, onFollow, globalState)
        }}
        type="button"
      >
        {subscribed ? '已关注' : '关注'}
      </button>
      {avatar_styles}
      <style jsx>
        {`
          .card {
            position: relative;
            height: 64px;
            width: 100%;
            background: white;
            margin-bottom: 8px;
          }
          .avatar {
            position: relative;
            display: inline-block;
            width: 56px;
            height: 56px;
            box-sizing: border-box;
            margin: 4px 8px 4px 16px;
          }
          .inner {
            border-radius: 50%;
            position: absolute;
            top: 10px;
            left: 10px;
            height: 36px;
            width: 36px;
            background-image: url(${user_info.avatar});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }

          .label {
            text-align: center;
            left: 7px;
            top: 38px;
            position: absolute;
            width: 38px;
            height: 14px;
            border-radius: 2px;
            background-color: #ffcd32;
            color: #1a1a1a;
            border: solid 2px white;
            box-sizing: content-box;
            line-height: 12px;
          }
          .for-chrome {
            display: inline-block;
            -webkit-transform: scale(0.8);
            font-size: 10px;
            font-weight: 500;
          }
          .title {
            position: absolute;
            left: 80px;
            top: 12px;
            font-size: 14px;
            font-weight: 500;
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .follower-num {
            position: absolute;
            left: 80px;
            top: 34px;
            font-size: 12px;
            line-height: 1.5;
            color: #979aa8;
          }
          .follow-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            padding: 0;
            border: 0;
            outline: 0;
            width: 80px;
            height: 24px;
            background-color: #ffcc32;
            border-radius: 2px;
            text-align: center;
            color: #1f2129;
            font-size: 14px;
            cursor: pointer;
          }

          .follow-btn.followed {
            background-color: #f2f4fa;
            color: #979aa8;
            cursor: auto;
          }
        `}
      </style>
    </div>
  )
}

const getMyFollowees = (
  setMyfollowees: (data: FolloweeData[]) => void,
  setFailed: (b: boolean) => void,
  setLoading: (b: boolean) => void
) => {
  // 获取我的关注
  setLoading(true)
  fetch(getUrl('/motor/m_api/pc_live_my_relation_list'))
    .then(res => res.json())
    .then(json => {
      setLoading(false)
      setFailed(false)
      if (!json.data || !(Array.isArray(json.data) && json.data.length > 0)) {
        setMyfollowees([])
      } else {
        setMyfollowees(json.data)
      }
    })
    .catch(e => {
      setLoading(false)
      console.error('获取我的关注数据失败', e)
      setFailed(true)
    })
}

export default function Followees() {
  const [failed, setFailed] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [tagNow, setTagNow] = useState<'mine' | 'recommend'>('mine')
  const [recfollowees, setRecfollowees] = useState<FolloweeData[]>([])
  const [myfollowees, setMyfollowees] = useState<FolloweeData[]>([])

  const { state: globalStore } = useContext(GlobalStoreContext)
  const { userData } = globalStore

  const isLogin = !!(userData && userData.user_id)

  const onFollow = (user_id: string, success = true) => {
    if (success) {
      const recFollowee = recfollowees.find(
        e => e.user_info.user_id_str === user_id
      )
      recFollowee && (recFollowee.fans_num += 1)
      recFollowee && (recFollowee.subscribed = true)
      setRecfollowees([...recfollowees])

      if (!myfollowees.find(e => e.user_info.user_id_str === user_id)) {
        recFollowee && myfollowees.push(recFollowee)
        setMyfollowees([...myfollowees])
      }
    } else {
      const reFollowee = recfollowees.find(
        e => e.user_info.user_id_str === user_id
      )
      reFollowee && (reFollowee.fans_num -= 1)
      reFollowee && (reFollowee.subscribed = false)
      setRecfollowees([...recfollowees])

      if (myfollowees.find(e => e.user_info.user_id_str === user_id)) {
        myfollowees.pop()
        setMyfollowees([...myfollowees])
      }
    }
  }

  useEffect(() => {
    // 请求推荐直播间数据
    fetch(getUrl('/motor/m_api/pc_live_recommend'))
      .then(res => res.json())
      .then(json => {
        if (!json.data || !(Array.isArray(json.data) && json.data.length > 0)) {
          setRecfollowees([])
        } else {
          setRecfollowees(json.data)
        }
      })
      .catch(e => {
        console.error('获取推荐直播间数据失败', e)
      })

    getMyFollowees(setMyfollowees, setFailed, setLoading)
  }, [])

  useEffect(() => {
    setTagNow('recommend')
  }, [recfollowees])

  return (
    <>
      <div className="tag-line">
        {recfollowees.length !== 0 && (
          <span
            className={`recommend ${tagNow === 'recommend' ? 'active' : ''}`}
            data-type="recommend"
            onClick={e => {
              sendTeaCommEvent('click_home_live_follow_tab', {
                sub_tab: '推荐直播间',
              })
              setTagNow('recommend')
            }}
          >
            推荐直播间
          </span>
        )}

        <span
          className={`mine ${tagNow === 'mine' ? 'active' : ''}`}
          data-type="mine"
          onClick={e => {
            sendTeaCommEvent('click_home_live_follow_tab', {
              sub_tab: '我的关注',
            })
            setTagNow('mine')
          }}
        >
          我的关注
        </span>
      </div>
      <div className="card-list">
        {tagNow === 'recommend' ? (
          recfollowees.map(e => (
            <Card data={e} key={e.room_id} onFollow={onFollow} />
          ))
        ) : failed ? (
          <div className="no-data failed">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <img src={error_pic} />
                <span>网络异常，点击重试</span>
                <button
                  type="button"
                  className="retry"
                  onClick={() => {
                    getMyFollowees(setMyfollowees, setFailed, setLoading)
                  }}
                >
                  重试
                </button>
              </>
            )}
          </div>
        ) : isLogin ? (
          myfollowees.length > 0 ? (
            myfollowees.map(e => <MineCard {...e} key={e.room_id} />)
          ) : (
            <div className="no-data">
              <img src={empty_pic} />
              <span>
                暂时没有主播在直播哦,
                <br />
                换个时间再来吧~
              </span>
            </div>
          )
        ) : (
          <div className="unlogged">
            <img src={empty_pic} />
            <span>暂无直播</span>
            <button
              type="button"
              onClick={() => {
                window.location.href = getLoginHref()
              }}
            >
              登录看主播
            </button>
          </div>
        )}
      </div>

      <style jsx>
        {`
          .tag-line {
            width: 100%;
            height: 32px;
            display: inline-flex;
            align-items: center;
          }
          .recommend,
          .mine {
            line-height: 24px;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            color: #1f2129;
            cursor: pointer;
          }

          .recommend {
            margin-right: 32px;
          }

          .active {
            font-size: 24px;
            line-height: 36px;
          }
          .card-list {
            margin-top: 16px;
            height: 341px;
            overflow-y: scroll;
          }
          .no-data {
            background-color: white;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
          }

          .no-data img {
            width: 100px;
            height: 100px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;
          }
          .no-data span {
            text-align: center;
            color: #606370;
            font-size: 14px;
          }

          .unlogged {
            background-color: white;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .unlogged img {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
          }
          .unlogged span {
            color: #606370;
            font-size: 14px;
          }
          .unlogged button {
            width: 102px;
            height: 32px;
            border-radius: 2px;
            background-color: #ffcc32;
            outline: 0;
            border: 0;
            margin-top: 12px;
            cursor: pointer;
          }
          .failed {
            align-items: center;
          }
          .failed span {
            margin-bottom: 12px;
          }
          .retry {
            text-align: center;
            width: 76px;
            height: 32px;
            border-radius: 2px;
            background-color: #ffcc32;
            font-size: 14px;
            color: #1f2129;
            outline: 0;
            border: 0;
            line-height: 32px;
            cursor: pointer;
          }
        `}
      </style>
    </>
  )
}
