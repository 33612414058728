import React, { useState, useEffect, useRef, useContext } from 'react'
import Link from 'next/link'
import get from 'lodash/get'
import getUrl from 'lib/url'
import Swiper, { SwiperInstance } from 'react-id-swiper'
import 'lib/swiper.less'
import { focus } from 'lib/ugc-interact'
import { getLoginHref } from 'lib/get-login-href'
import { GlobalStoreContext } from 'Components/store'
import Loading from 'Components/loading'
import { sendTeaCommEvent } from 'lib/tea-analyze'
import AvatarStyle from 'Constants/global-styles/avatar-style'
const dateImg = require('static/date@3x.png')
const SWIPER_LENGTH = 6
const error_pic = require('static/error-pic@3x.jpg')

interface CardProps extends CelebrityInfo {
  index: number
  onFollow: (uid: string, b: boolean) => void
}

const follow = (
  isSubscribing: boolean,
  user_id_str: string,
  onFollow: (uid: string, b: boolean) => void,
  setIsSubscribing: (x: boolean) => void,
  globalState: { userData?: { user_id?: string } }
) => {
  if (isSubscribing) return
  sendTeaCommEvent('click_home_live_top_anchor_follow', {
    anchor_id: user_id_str,
  })
  const my_user_id: number = get(globalState, 'userData.user_id')
  if (!my_user_id) {
    window.location.href = getLoginHref()
    return
  }
  // 先假设关注成功
  setIsSubscribing(true)
  onFollow(user_id_str, true)
  focus({
    user_id: Number(my_user_id),
    to_user_id: Number(user_id_str),
  }).catch(() => {
    // 如果失败了，重置关注状态
    setIsSubscribing(false)
    onFollow(user_id_str, false)
  })
}

function Card(props: CardProps) {
  const {
    user_id_str = '',
    name,
    avatar_url = '',
    is_following = false,
    desc,
    index,
    onFollow,
    status,
    room_id,
  } = props

  const { state: globalState } = useContext(GlobalStoreContext)
  const { className: avatar_class, styles: avatar_styles } = AvatarStyle
  const [isSubscribing, setIsSubscribing] = useState(is_following)

  // 更新订阅信息
  useEffect(() => {
    setIsSubscribing(is_following)
  }, [is_following])

  return (
    <div className="card">
      <div className="corner-index">
        <span>{index + 1}</span>
      </div>
      <span className="avatar">
        {status === 2 ? (
          <a
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            href={`https://live.ixigua.com/room/${room_id}`}
            rel="unsafe-url"
          >
            <span className={`inner ${status === 2 ? avatar_class : ''}`} />
            <span className="label">
              <span className="for-chrome">直播中</span>
            </span>
          </a>
        ) : (
          <Link
            href={`/profile?theUserId=${user_id_str}`}
            as={`/user/ugc_profile/${user_id_str}`}
          >
            <a target="_blank">
              <span className="inner" />
            </a>
          </Link>
        )}
      </span>

      <span className="title">
        <Link
          href={`/profile?theUserId=${user_id_str}`}
          as={`/user/ugc_profile/${user_id_str}`}
        >
          <a target="_blank">{name}</a>
        </Link>
      </span>
      <span className="live-index">{desc}</span>
      <button
        className={`follow-btn ${isSubscribing ? 'followed' : ''}`}
        type="button"
        onClick={() => {
          follow(
            isSubscribing,
            user_id_str,
            onFollow,
            setIsSubscribing,
            globalState
          )
        }}
      >
        {isSubscribing ? '已关注' : '关注'}
      </button>
      {avatar_styles}
      <style jsx>
        {`
      .card{
        position: relative;
        height: 100%;
        width: 186px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 0 0 auto;
        border-radius: 2px;
      }

      .card:not(:nth-child(6n)){
        margin-right: calc( ( 100% - 6 * 186px ) / 5);
      }

      .corner-index{
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        min-width: 24px;
        height: 24px;
        font-family: ByteNumber;
        font-size: 18px;
        font-weight: bold;
        color: white;
        border-radius: 2.7px 0 2.7px 0;
        background-color: ${
    index === 0
      ? '#e62021'
      : index === 1
        ? '#e65800'
        : index === 2
          ? ' #f5b330'
          : '#c9cbd6'
    };
      }

      .corner-index > span{
        vertical-align: -2px;
      }
      .avatar{
        margin-top: 20px;
        position: relative;
        height: 94px;
        width: 94px;
        flex: 0 0 auto;
        cursor: pointer;
      }

      .inner{
        border-radius: 50%;
        position: absolute;
        background-image: url('${avatar_url}');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        left: 11px;
        top: 11px;
        height: 72px; 
        width: 72px;
        background-color: rgba(0, 0, 0, .04);
        border: solid 1px rgba(0, 0, 0, .06);
        box-sizing: content-box;
        background-clip: content-box;
      }
      .title{
        margin-top 16px;
        flex: 0 0 auto;
        font-size: 16px;
        color: #1f2129;
        font-weight: 500;
        cursor: pointer;
      }
      .label {
        text-align: center;
        left: 26px;
        bottom: 5px;
        position: absolute;
        width: 38px;
        height: 14px;
        border-radius: 1px;
        background-color: #ffcd32;
        color: #1a1a1a;
        border: solid 2px white;
        box-sizing: content-box;
        line-height: 12px;
      }
      .for-chrome {
        display: inline-block;
        -webkit-transform: scale(0.8);
        font-size: 10px;
        font-weight: 500;
      }
      .live-index{
        margin-top: 4px;
        flex: 0 0 auto;
        font-size: 12px;
        line-height: 1.5;
        color: #979aa8;
      }
      .follow-btn{
        margin-top: 12px;
        flex: 0 0 auto;
        outline: 0;
        border: 0;
        border-radius: 2px;
        background-color: #ffcc32;
        font-size: 14px;
        color: #1f2129;
        text-align: center;
        width: 80px;
        height: 24px;
        cursor: pointer;
      }

      .follow-btn.followed{
        background-color: #f2f4fa;
        color: #979aa8;
        cursor: auto;
      }
    `}
      </style>
    </div>
  )
}

export interface CelebrityWeek {
  anchor_id: string
  desc: string
  date_week: string
}

export interface CelebrityMonth {
  anchor_id: string
  desc: string
  date_month: string
}

interface CelebrityInfo {
  user_id_str: string
  name: string
  avatar_url: string
  is_following: boolean
  desc: string
  status: number
  room_id: string
}
const left_ic_img = require('static/left-ic.svg')

const extractDateTags = (
  data: (CelebrityWeek & CelebrityMonth)[],
  setDateTags: (tags: string[]) => void,
  field: 'date_month' | 'date_week'
) => {
  const tags: string[] = []
  const dates = data.map(e => e[field])
  dates.sort((a, b) => {
    return Date.parse(b) - Date.parse(a)
  })
  for (const date of dates) {
    if (!tags.includes(date)) {
      tags.push(date)
    }
  }
  setDateTags(tags)
}

const groupBy = <T extends unknown>(arr: T[], len: number) => {
  if (!arr || arr.length === 0 || len <= 0) {
    return []
  }
  const result = []
  let i = 0
  let sub: T[] = []
  for (let j = 0; j < arr.length; j++) {
    if (i === len) {
      result.push(sub)
      sub = []
      i = 0
    }
    sub.push(arr[j])
    i++
    if (j === arr.length - 1) {
      result.push(sub)
    }
  }
  return result
}

const transferDate = (dateStr: string, addDays = 6) => {
  try {
    if (!dateStr || dateStr === '') {
      return ''
    }
    const date = new Date(Date.parse(dateStr))
    const d = new Date(date.getTime() + addDays * 86400000)
    return `${`0${d.getMonth() + 1}`.slice(-2)}/${`0${d.getDate()}`.slice(-2)}`
  } catch (e) {
    console.error(e)
    return ''
  }
}

const getAllIDs = (
  data: (CelebrityWeek & CelebrityMonth)[],
  dateTags: string[],
  selectDateIndex: number
): string => {
  if (data.length === 0 || dateTags.length === 0) {
    return ''
  }
  const filtered = data.filter(
    e =>
      e.date_week === dateTags[selectDateIndex] ||
      e.date_month === dateTags[selectDateIndex]
  )
  filtered.sort((a: any, b: any) => a.rank - b.rank)

  return filtered.map(e => e.anchor_id).join(',')
}

const getCelebrity = async (
  dateTags: string[],
  selectDateIndex: number,
  setInfo: (x: CelebrityInfo[]) => void,
  data: (CelebrityWeek & CelebrityMonth)[]
) => {
  const ids = getAllIDs(data, dateTags, selectDateIndex)
  if (ids.length === 0) {
    return
  }
  return fetch(getUrl(`/motor/dlive/api/get_room_by_users?user_ids=${ids}`))
    .then(res => res.json())
    .then(json => {
      if (json.status !== 0) {
        throw new Error(`请求达人数据失败。 error status: ${json.status}
                         error message: ${json.message}`)
      }
      setInfo(
        json.data.map(
          (e: {
            user_info: {
              user_id_str?: string
              name?: string
              avatar_url?: string
            }
            is_following?: boolean
            user_room_data_list: {
              status: number
              id_str: string
            }[]
          }) => {
            const {
              user_info: { user_id_str = '', name = '', avatar_url = '' },
              is_following = false,
              user_room_data_list = [],
            } = e
            const desc =
              data.find(
                e =>
                  e.anchor_id === user_id_str &&
                  (e.date_week || e.date_month) === dateTags[selectDateIndex]
              )?.desc || ''
            const { status = 0, id_str: room_id = '' } =
              user_room_data_list?.[0] || {}
            return {
              user_id_str,
              name,
              avatar_url,
              is_following,
              desc,
              status,
              room_id,
            }
          }
        )
      )
    })
}

function Celebrity({ wD, mD }: { wD: CelebrityWeek[]; mD: CelebrityMonth[] }) {
  const [tagNow, setTagNow] = useState<'weekly' | 'monthly'>('monthly')
  // const [data, setData] = useState<Celebrity[]>([])
  const [weeklyData, setWeeklyData] = useState<CelebrityWeek[]>([])
  const [monthlyData, setMonthlyData] = useState<CelebrityMonth[]>([])
  //const [info, setInfo] = useState<CelebrityInfo[]>([])
  const [weeklyInfo, setWeeklyInfo] = useState<CelebrityInfo[]>([])
  const [monthlyInfo, setMonthlyInfo] = useState<CelebrityInfo[]>([])
  //const [dateTags, setDateTags] = useState<string[]>([])
  const [weeklyDateTags, setWeeklyDateTags] = useState<string[]>([])
  const [monthlyDateTags, setMonthlyDateTags] = useState<string[]>([])
  const [selectDateIndex, setSelectDateIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [swiper, setSwiper] = useState<SwiperInstance>(null)
  const [failed, setFailed] = useState(false)
  const [fold, setFold] = useState(true)
  const ref = useRef<HTMLDivElement>(null)

  const params = {
    navigation: {
      nextEl: `.swiper-button-next`,
      prevEl: `.swiper-button-prev`,
    },
    height: 232,
    loop: false,
    autoplay: false,
    preloadImages: true,
    updateOnImagesReady: true,
    getSwiper(swiper: SwiperInstance) {
      setSwiper(swiper)
    },
  }

  useEffect(() => {
    setWeeklyData(wD)
  }, [wD])

  useEffect(() => {
    setMonthlyData(mD)
  }, [mD])

  // 提取月标签项
  useEffect(() => {
    extractDateTags(
      monthlyData as (CelebrityWeek & CelebrityMonth)[],
      setMonthlyDateTags,
      'date_month'
    )
  }, [monthlyData])

  // 提取周标签项
  useEffect(() => {
    extractDateTags(
      weeklyData as (CelebrityWeek & CelebrityMonth)[],
      setWeeklyDateTags,
      'date_week'
    )
  }, [weeklyData])

  const fetchCelebrityData = (tag: 'weekly' | 'monthly') => {
    const data = tag === 'weekly' ? weeklyData : monthlyData
    const dateTags = tag === 'weekly' ? weeklyDateTags : monthlyDateTags
    const setInfo = tag === 'weekly' ? setWeeklyInfo : setMonthlyInfo
    setLoading(true)
    getCelebrity(
      dateTags,
      selectDateIndex,
      setInfo,
      data as (CelebrityWeek & CelebrityMonth)[]
    )
      .then(() => {
        setLoading(false)
        setFailed(false)
      })
      .catch(e => {
        setLoading(false)
        setFailed(true)
        console.error(e)
      })
  }

  // 请求周榜达人数据 & 请求月榜达人数据
  useEffect(() => {
    fetchCelebrityData('weekly')
  }, [weeklyData, weeklyDateTags, selectDateIndex])
  useEffect(() => {
    fetchCelebrityData('monthly')
  }, [monthlyData, monthlyDateTags, selectDateIndex])

  // 切换标签 从头展示
  useEffect(() => {
    setSelectDateIndex(0)
    if (swiper && swiper.slideTo) {
      swiper.slideTo(0)
    }
  }, [tagNow])

  const onFollow = (user_id: string, success: boolean) => {
    const wi = weeklyInfo.find(e => e.user_id_str === user_id)
    const mi = monthlyInfo.find(e => e.user_id_str === user_id)
    if (wi) {
      wi.is_following = success
    }
    if (mi) {
      mi.is_following = success
    }

    setWeeklyInfo(weeklyInfo)
    setMonthlyInfo(monthlyInfo)
  }

  const renderCards = () => {
    const dateTags = tagNow === 'weekly' ? weeklyDateTags : monthlyDateTags
    const dateLength = tagNow === 'weekly' ? 7 : 3
    return (
      <>
        {dateTags.slice(0, dateLength).map((e, index) => (
          <span
            className={`period ${selectDateIndex === index ? 'active' : ''}`}
            key={e}
            onClick={() => {
              setSelectDateIndex(index)
            }}
          >
            {tagNow === 'weekly' ? `${e} - ${transferDate(e)}` : e}
          </span>
        ))}
        <style jsx>
          {`
            .period {
              padding-top: 4px;
              padding-bottom: 4px;
              text-align: center;
              font-size: 14px;
              color: #333333;
            }
            .period:hover {
              background-color: rgba(255, 205, 50, 0.24);
            }

            .period.active {
              background-color: #ffcd32;
            }
          `}
        </style>
      </>
    )
  }

  const mapData = (arr: CelebrityInfo[], ai: number) => (
    <div className="card-wrapper" key={ai}>
      {arr.map((e, index) => (
        <Card
          {...e}
          index={ai * 6 + index}
          key={index + ai + get(e, 'user_id_str', '')}
          onFollow={onFollow}
        />
      ))}
      <style jsx>
        {`
          .card-wrapper {
            height: 232px;
            width: 100%;
            display: flex;
            align-items: center;
          }
        `}
      </style>
    </div>
  )

  return (
    <div className="celebrity-wrapper">
      <div className="first-line">
        <h2 className="title">达人主播榜</h2>
        <div
          className="date-picker"
          onFocus={() => {
            setFold(false)
          }}
          onBlur={() => {
            setFold(true)
          }}
          tabIndex={0}
          ref={ref}
        >
          {tagNow === 'weekly' ? (
            <span className="date">
              {weeklyDateTags[selectDateIndex] || ''} -{' '}
              {transferDate(weeklyDateTags[selectDateIndex]) || ''}
            </span>
          ) : (
            <span className="date">
              {monthlyDateTags[selectDateIndex] || ''}
            </span>
          )}

          <div
            className={`date-list ${fold ? 'fold' : ''}`}
            onClick={() => {
              if (ref.current) {
                ref.current.blur()
              }
            }}
          >
            {renderCards()}
          </div>
        </div>
        <div className="tag-line">
          <span
            className={`monthly ${tagNow === 'monthly' ? 'active' : ''}`}
            data-type="monthly"
            onClick={() => {
              setTagNow('monthly')
            }}
          >
            月榜
          </span>
          <span
            className={`weekly ${tagNow === 'weekly' ? 'active' : ''}`}
            data-type="weekly"
            onClick={() => {
              setTagNow('weekly')
            }}
          >
            周榜
          </span>
        </div>
      </div>
      <div className="card-list">
        {failed ? (
          <div className="failed">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <img src={error_pic} />
                <span>网络异常，点击重试</span>
                <button
                  type="button"
                  className="retry"
                  onClick={() => {
                    fetchCelebrityData(tagNow)
                  }}
                >
                  重试
                </button>
              </>
            )}
          </div>
        ) : isLoading ? (
          <Loading />
        ) : tagNow === 'weekly' ? (
          weeklyInfo.length > 0 ? (
            <Swiper {...params}>
              {groupBy(weeklyInfo, SWIPER_LENGTH).map(mapData)}
            </Swiper>
          ) : null
        ) : monthlyInfo.length > 0 ? (
          <Swiper {...params}>
            {groupBy(monthlyInfo, SWIPER_LENGTH).map(mapData)}
          </Swiper>
        ) : null}
      </div>
      <style jsx>
        {`
          .celebrity-wrapper {
            width: 100%;
            height: 100%;
          }
          .first-line {
            width: 100%;
            height: 36px;
            display: flex;
            align-items: center;
            position: relative;
          }
          .title {
            font-size: 24px;
            font-weight: 500;
            flex: 0 0 auto;
          }
          .date-picker {
            flex: 0 0 auto;
            position: absolute;
            right: 168px;
            display: inline-flex;
            align-items: center;
            width: 200px;
            height: 32px;
            background-color: white;
            cursor: pointer;  
            border-radius: 2px;
            border: solid 1px #e6e8f2;
          }
          
          .date-picker:focus {
            outline: 0;
          }
          .date-picker::before {
            content: '';
            width: 16px;
            margin-left: 8px;
            height: 16px;
            background-image: url(${dateImg});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .date {
            margin-left: 16px;
            font-size: 14px;
            line-height: 20px;
            color: #1f2129;
          }
          .date-list {
            cursor: pointer;
            position: absolute;
            top: 40px;
            z-index: 2;
            width: 240px;
            background: white;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            white-space: nowrap;
            flex-wrap: nowrap;
            padding: 12px 0 16px 0; 
          }
          .fold{
            visibility: hidden;
          }
          .tag-line {
            width: 152px;
            height: 32px;
            position: absolute;
            right: 0;
          }
          .weekly,
          .monthly {
            display: inline-block;
            width: 50%;
            height: 100%;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            border: solid 1px #1f2129;
            color: #1f2129;
            background-color: white;
            cursor: pointer;
          }
          .monthly {
            border-radius: 2px 0 0 2px;
          }
          .weekly {
            border-radius: 0 2px 2px 0;
          }
          .weekly.active, .monthly.active {
            color: white;
            background-color: #1f2129;
          }

          .card-list {
            position: relative;
            margin-top: 16px;
            width: 100%;
            height: 232px;

          }

          :global(.card-list .swiper-button-prev, .card-list .swiper-button-next) {
            background-color: rgba(0, 0, 0, .4);
            opacity: 1;
            width: 32px;
            height: 48px;
            border-radius: 8px;
            margin-top: -24px;
          }
          :global(.card-list .swiper-button-prev){
            border-radius: 0 8px 8px 0;
          }
          :global(.card-list .swiper-button-next){
            border-radius: 8px 0 0 8px;
          }
          :global(.card-list .swiper-button-prev::after, .card-list .swiper-button-next::after) {
            width: 24px;
            height: 24px;
            margin-top: 0;
            transform: translateY(-50%);
            margin-left: -12px;
            background-image: url('${left_ic_img}');
          }

          :global(.card-list .swiper-button-prev:hover, .card-list .swiper-button-next:hover) {
            opacity: 1;
            background-color: #f5b330;
            margin-top: -24px;
          }

          :global(.card-list .swiper-button-prev:hover::after, .card-list .swiper-button-next:hover::after) {
            background-image: url('${left_ic_img}');
          }

          :global(.card-list .swiper-button-disabled){
            display: none;
          }

          :global(.card-list .swiper-button-next:after, .card-list .swiper-button-next:hover::after){
            transform: translateY(-50%) rotate(180deg);
          }

          .failed {
            background-color: white;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .failed img {
            width: 100px;
            height: 100px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;
          }
          .failed span {
            text-align: center;
            color: #606370;
            font-size: 14px;
            margin-bottom: 12px;
          }
          .retry{
            text-align: center;
            width: 76px;
            height: 32px;
            border-radius: 2px;
            background-color: #ffcc32;
            font-size: 14px;
            color: #1f2129;
            outline: 0;
            border: 0;
            line-height: 32px;
            cursor: pointer;
          }
        `}
      </style>
    </div>
  )
}

export default Celebrity
