const getCurrentHref = () => window.location.href
const getHostName = () => window.location.hostname
const getBase64Href = () => window.btoa(getCurrentHref())
const getUserHost = () =>
  getCurrentHref().indexOf('pc.motor-boe.bytedance.net') > -1
    ? 'http://mp.motor-boe.bytedance.net'
    : 'https://mp.dcdapp.com'

export const getLoginHref = () =>
  `${getUserHost()}/login/?from=pc&redirect_url=${getBase64Href()}`
export const getRegisterHref = () =>
  `${getUserHost()}/register/?from=pc&redirect_url=${getBase64Href()}`
export const getLogoutHref = () =>
  `https://sso.toutiao.com/logout/?page=sso&aid=36&service=http://${getHostName()}/passport/web/logout/?next=${encodeURIComponent(
    getCurrentHref()
  )}`
