import css from 'styled-jsx/css'

const AvatarStyle = css.resolve`
  span::before,
  span::after {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    content: '';
  }
  span::before {
    z-index: 0;
    padding: 1px;
    border: 2px solid #ffcd32;
    animation: innerScale 0.4s infinite alternate;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }

  span::after {
    padding: 7.5px;
    border: solid 1.5px #ffcd32;
    animation: outerScale 0.8s infinite normal,
      outerOpacity 0.8s infinite normal;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1),
      cubic-bezier(0.4, 0, 0.6, 1);
  }

  span {
    border-radius: 50%;
  }
  @keyframes innerScale {
    0% {
      padding: 1px;
    }

    100% {
      padding: 2px;
    }
  }

  @keyframes outerScale {
    0% {
      padding: 1.5px;
    }

    100% {
      padding: 7.5px;
    }
  }

  @keyframes outerOpacity {
    0% {
      border-color: rgba(255, 225, 0, 1);
    }

    10% {
      border-color: rgba(255, 225, 0, 1);
    }

    100% {
      border-color: rgba(255, 225, 0, 0);
    }
  }
`

export default AvatarStyle
