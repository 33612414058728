import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import get from 'lodash/get'
import getLocation from 'lib/get-location'
import getUrl from 'lib/url'
import { sendTeaCommEvent } from 'lib/tea-analyze'
import Loading from 'Components/loading'
import ScrollLoader from 'Components/scroll-loader'
import serializeObject from '@arsenal/arsenal/modules/serializeObject'
import Cookies from '@arsenal/arsenal/modules/Cookies'
import getFakeWebid from 'lib/fake-webid'
import { useCityName } from 'Components/hooks'
import formatNumber from '@arsenal/arsenal/modules/formatNumber'

const LIVE_TYPE = 5258
const all_live_img = require('static/icon-all@3x.png')
const local_img = require('static/icon-local@3x.png')
const kol_img = require('static/icon-kol@3x.png')
const empty_pic = require('static/empty-pic@3x.png')
interface BrandCard {
  brand_id?: string
  name: string
  logo: string
  brand_initial?: string
}

interface BrandProps {
  brandCard: BrandCard
  active?: boolean
  onClickTag: (bid: string) => void
}

const live_black_gif = require('static/live-black.gif')
export interface LiveData {
  user_info: {
    name: string
    avatar_url: string
    user_id: string
  }
  share_info: {
    title: string
    share_url: string
  }
  user_count: number
  image_list: [{ url: string }]
  status_icon: string
  status: number
  unique_id_str: string
  card_content?: { list: [IBannerList] }
  type: number
  popularity_display?: string
  behot_time?: number
}

export interface IBannerList {
  open_url: string
  img_url: string
  title: string
  gid: string
  user_info?: {
    user_id: string
  }
}

interface CardProps extends LiveData {
  onClick: (uid: string, rid: string) => void
}

function Card(props: CardProps) {
  const {
    unique_id_str,
    status: type,
    share_info: { title, share_url },
    image_list,
    user_info: { name, avatar_url, user_id },
    user_count,
    onClick,
  } = props
  const url = image_list[0]?.url || ''
  return (
    <div
      className="card"
      onClick={e => {
        onClick(user_id, unique_id_str)
      }}
    >
      <a
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        rel="unsafe-url"
        href={`https://live.ixigua.com/room/${unique_id_str}`}
      >
        <div className="show-main">
          <div className="live-box">
            <span className="live-box-left">
              {type === 2 && (
                <img src={live_black_gif} className="living-gif" />
              )}
              {type === 2 ? '直播中' : '直播预告'}{' '}
            </span>
            <span className="live-box-right">
              <span>{formatNumber(user_count)}人在观看</span>
            </span>
          </div>
        </div>
      </a>

      <div className="show-bottom">
        <span className="title">
          <a
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="unsafe-url"
            href={`https://live.ixigua.com/room/${unique_id_str}`}
          >
            {title}
          </a>
        </span>
        <Link
          href={`/profile?theUserId=${user_id}`}
          as={`/user/ugc_profile/${user_id}`}
        >
          <a target="_blank">
            <span className="avatar" />
            <span className="author">{name}</span>
          </a>
        </Link>
      </div>

      <style jsx>
        {`
          .card {
            width: 388px;
            height: 100%;
            flex: 0 0 auto;
            margin-bottom: 16px;
            border-radius: 2px;
            overflow: hidden;
          }
          .card:not(:nth-child(3n)){
            margin-right: 16px;
          }
          .show-main {
            width: 100%;
            height: 218px;
            position: relative;
            background-image: url('${url}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          .living-gif {
            width: 16px;
            height: 16px;
            object-fit: cover;
            object-position: center;
          }

          .live-box {
            position: absolute;
            height: 24px;
            z-index: 1;
            display: flex;
            align-items: center;
            bottom: 12px;
            left: 12px;
          }

          .live-box-left {
            display: inline-flex;
            align-items: center;
            padding: 4px;
            height: 24px;
            border-radius: 2px 0 0 2px;
            background-color: #ffcc32;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            color: #1f2129;
          }

          .live-box-right {
            position: relative;
            display: inline-block;
            padding: 6px 8px;
            height: 24px;
            border-radius: 0 2px 2px 0;

            font-size: 12px;
            font-weight: 500;
            line-height: 1;
            color: white;
          }
          .live-box-right > span {
            position: relative;
          }
          .live-box-right::before {
            left: 0;
            top: 0;
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
          }
          .show-bottom {
            width: 100%;
            height: 72px;
            position: relative;
            border-radius: 2px;
            background-color: white;
          }
          .avatar {
            position: absolute;
            bottom: 12px;
            left: 12px;
            width: 16px;
            height: 16px;
            background-color: rgba(255, 255, 255, 0.08);
            background-image: url('${avatar_url}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
          }
          .author {
            position: absolute;
            bottom: 10px;
            left: 32px;
            font-size: 12px;
            line-height: 1.5;
            color: #979aa8;
          }
          .title {
            position: absolute;
            top: 12px;
            left: 12px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #1f2129;
          }
        `}
      </style>
    </div>
  )
}

function BrandCard(props: BrandProps) {
  const {
    brandCard: { logo, name, brand_id = '' },
    active,
    onClickTag,
  } = props
  return (
    <div
      className={`brand-card ${active ? 'active' : ''}`}
      onClick={e => {
        sendTeaCommEvent('click_home_live_living_select_tab', {
          sub_tab: brand_id,
        })
        onClickTag(brand_id)
      }}
    >
      <span className="brand-logo" />
      <span className="brand-name">{name}</span>

      <style jsx>
        {`
          .brand-card {
            width: 78px;
            height: 80px;
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background-color: white;
            border-radius: 2px;
            margin-bottom: 6px;
            box-sizing: border-box;
            cursor: pointer;
          }

          .brand-card:hover{
            background-color: rgba(255, 205, 50, 0.24);
            box-shadow: 0 2px 8px rgba(0, 0, 0, .08 );
          }

          .brand-card:not(:nth-child(14n)){
            margin-right: calc( ( 1196px - 1092px ) / 13 );
          }

          .brand-card.active{
            box-sizing: border-box;
            border-bottom: solid 4px #ffcd32;
          }

          .brand-logo {
            width: 36px;
            height: 36px;
            background-image: url('${logo}');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 8px auto 2px;
          }

          .brand-name {
            font-size: 14px;
            line-height: 22px;
            color: #1f2129;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 0 4px; 
            width: 100%;
            text-align: center;
          }
        `}
      </style>
    </div>
  )
}

interface RawLiveData {
  info: {
    user_info?: {} | undefined
    share_info?: {
      title: string
      share_url: string
    }
    status_icon: string
    image_list?: { url: string }[]
    status: number
    user_count?: 0
    card_content?: {}
  }
  unique_id_str: string
  type: number
}

export const mapShownData = (e: RawLiveData) => {
  const {
    user_info = {},
    share_info = { title: '', share_url: '' },
    status_icon,
    image_list = [],
    status,
    user_count = 0,
    card_content = {},
  } = e.info
  return {
    user_count,
    user_info,
    share_info,
    image_list,
    status_icon,
    status,
    unique_id_str: e.unique_id_str,
    type: e.type,
    card_content,
  }
}

export default function LiveShow(props: { getLiveData: () => LiveData[] }) {
  const { getLiveData } = props
  const [liveData, setLiveData] = useState<LiveData[]>([])
  const [brands, setBrands] = useState<BrandCard[]>([])
  const [brandNow, setBrandNow] = useState('all')
  const [shownData, setShownData] = useState<LiveData[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [switched, setSwitched] = useState(false)
  const [loadMoreCount, setLoadMoreCount] = useState(1)
  const [lastCount, setLastCount] = useState(0)
  const [lastMaxTime, setLastMaxTime] = useState(0)

  const cityName = useCityName()
  const filterShownData = (e: RawLiveData) => {
    return !shownData.map(e => e.unique_id_str).includes(e.unique_id_str)
  }

  useEffect(() => {
    setLiveData(getLiveData())
  }, [getLiveData])

  // 请求正在直播的品牌标签
  useEffect(() => {
    const url = `motor/pleasure/epidemic/get_live_tabs?device_id=undefined`
    fetch(getUrl(url))
      .then(res => res.json())
      .then(json => {
        const { brands } = json.data
        brands.unshift({ brand_id: 'bigv', name: '大咖直播', logo: kol_img })
        brands.unshift({ brand_id: 'local', name: '本地直播', logo: local_img })
        brands.unshift({
          brand_id: 'all',
          name: '全部直播',
          logo: all_live_img,
        })

        setBrands(json.data.brands)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  // 目的：区分初次加载后切换到全部直播与第一次加载
  useEffect(() => {
    if (switched) {
      setLoading(true)
      return
    }
    if (brandNow !== 'all') {
      setSwitched(true)
    }
  }, [brandNow])

  // 获取正在展示的直播数据
  useEffect(() => {
    const web_id = Cookies('tt_webid') || ''
    setLoading(true)
    if (brandNow === 'all') {
      if (!switched) {
        setLoading(false)
        setShownData(liveData)
        setLastMaxTime(liveData[liveData.length - 1]?.behot_time || lastMaxTime)
      } else {
        const defaultParams = {
          tt_from: 'pull',
          category: 'motor_car_live',
          refer: 1,
          count: 12,
          impression_info: JSON.stringify({
            page_id: 'page_category',
            sub_tab: 'motor_car_live',
          }),
          motor_feed_extra_params: JSON.stringify({
            new_feed: true,
            feed_type: 3,
            refresh_num: 0,
            loadmore_num: 0,
            from_page_id: 'page_category',
            live_sub_tab: 'all',
          }),
          max_behot_time: 0,
          version_code: 600,
          channel: 'web',
          device_platform: 'web',
          aid: 1839,
          user_id: web_id,
        }
        fetch(
          getUrl(
            `/motor/stream/api/news/feed/web/v46/?${serializeObject(
              defaultParams
            )}`
          )
        )
          .then(res => res.json())
          .then(json => {
            setLoading(false)
            setLastMaxTime(
              json.data?.[json.data?.length - 1]?.info?.behot_time ||
                lastMaxTime
            )
            setShownData(
              json.data
                .map(mapShownData)
                .filter((e: { type: number }) => e.type === LIVE_TYPE)
            )
          })
          .catch(e => {
            console.error(e)
          })
      }
    } else if (brandNow === 'local') {
      const defaultParams = {
        tt_from: 'pull',
        category: 'motor_car_live_complex',
        refer: 1,
        count: 12,
        impression_info: JSON.stringify({
          page_id: 'page_category',
          sub_tab: 'motor_car_live_complex',
        }),
        motor_feed_extra_params: JSON.stringify({
          new_feed: true,
          feed_type: 0,
          refresh_num: 23,
          loadmore_num: 0,
          from_page_id: 'page_category',
          live_sub_tab: 'local',
          live_brand_id: '61',
          source_from: 'pc',
          pc_city: cityName,
        }),
        channel: 'web',
        device_platform: 'web',
        aid: 1839,
        user_id: web_id,
      }
      fetch(
        getUrl(
          `/motor/stream/api/news/feed/web/v46/?${serializeObject(
            defaultParams
          )}`
        )
      )
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          setLastCount(json.data?.length || lastCount)
          setShownData(json.data.map(mapShownData))
        })
        .catch(e => {
          console.error(e)
        })
    } else if (brandNow === 'bigv') {
      const defaultParams = {
        tt_from: 'pull',
        category: 'motor_car_live_complex',
        refer: 1,
        count: 12,
        impression_info: JSON.stringify({
          page_id: 'page_category',
          sub_tab: 'motor_car_live_complex',
        }),
        motor_feed_extra_params: JSON.stringify({
          new_feed: true,
          feed_type: 3,
          refresh_num: 1,
          loadmore_num: 0,
          from_page_id: 'page_category',
          live_sub_tab: 'kol',
        }),
        channel: 'web',
        device_platform: 'web',
        aid: 1839,
        user_id: web_id,
      }
      fetch(
        getUrl(
          `/motor/stream/api/news/feed/motor/v46/?${serializeObject(
            defaultParams
          )}`
        )
      )
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          setLastCount(json.data?.length || lastCount)
          setShownData(json.data.map(mapShownData))
        })
        .catch(e => {
          console.error(e)
        })
    } else if (!Number.isNaN(Number(brandNow))) {
      const defaultParams = {
        tt_from: 'pull',
        category: 'motor_car_live_complex',
        refer: 1,
        count: 12,
        impression_info: JSON.stringify({
          page_id: 'page_category',
          sub_tab: 'motor_car_live_complex',
        }),
        motor_feed_extra_params: JSON.stringify({
          new_feed: true,
          feed_type: 3,
          refresh_num: 4,
          loadmore_num: 0,
          from_page_id: 'page_category',
          live_sub_tab: 'brand',
          live_brand_id: brandNow,
        }),
        channel: 'web',
        device_platform: 'web',
        aid: 1839,
        user_id: web_id,
      }
      fetch(
        getUrl(
          `/motor/stream/api/news/feed/motor/v46/?${serializeObject(
            defaultParams
          )}`
        )
      )
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          if (
            !json.data ||
            !(Array.isArray(json.data) && json.data.length > 0)
          ) {
            setShownData([])
            return
          }
          setLastCount(json.data?.length || lastCount)
          setShownData(json.data.map(mapShownData))
        })
        .catch(e => {
          console.error(e)
        })
    }
    setLoadMoreCount(1)
  }, [brandNow, liveData])

  // 处理城市切换
  useEffect(() => {
    const web_id = Cookies('tt_webid') || ''
    if (brandNow === 'local') {
      const defaultParams = {
        tt_from: 'pull',
        category: 'motor_car_live_complex',
        refer: 1,
        count: 12,
        impression_info: JSON.stringify({
          page_id: 'page_category',
          sub_tab: 'motor_car_live_complex',
        }),
        motor_feed_extra_params: JSON.stringify({
          new_feed: true,
          feed_type: 0,
          refresh_num: 23,
          loadmore_num: 0,
          from_page_id: 'page_category',
          live_sub_tab: 'local',
          live_brand_id: '61',
          source_from: 'pc',
          pc_city: cityName,
        }),
        channel: 'web',
        device_platform: 'web',
        aid: 1839,
        user_id: web_id,
      }
      fetch(
        getUrl(
          `/motor/stream/api/news/feed/web/v46/?${serializeObject(
            defaultParams
          )}`
        )
      )
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          setLastCount(json.data?.length || lastCount)
          setShownData(json.data.map(mapShownData))
        })
        .catch(e => {
          console.error(e)
        })
    }
  }, [cityName])

  /* 懒加载 */
  function loadMoreHandler() {
    const web_id = Cookies('tt_webid') || ''
    setLoading(true)
    if (brandNow === 'all') {
      const defaultParams = {
        tt_from: 'pre_load_more',
        category: 'motor_car_live',
        refer: 1,
        count: 9,
        impression_info: JSON.stringify({
          page_id: 'page_category',
          sub_tab: 'motor_car_live',
        }),
        motor_feed_extra_params: JSON.stringify({
          new_feed: true,
          feed_type: 3,
          refresh_num: 2,
          loadmore_num: loadMoreCount,
          from_page_id: 'page_category',
          live_sub_tab: 'all',
        }),
        max_behot_time: lastMaxTime,
        channel: 'web',
        device_platform: 'web',
        aid: 1839,
        user_id: web_id,
      }
      fetch(
        getUrl(
          `/motor/stream/api/news/feed/motor/v46/?${serializeObject(
            defaultParams
          )}`
        )
      )
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          setLoadMoreCount(loadMoreCount + 1)
          setLastMaxTime(
            json.data?.[json.data?.length - 1]?.info?.behot_time || lastMaxTime
          )
          setShownData(
            shownData.concat(
              json.data
                .filter(filterShownData)
                .map(mapShownData)
                .filter((e: { type: number }) => e.type === LIVE_TYPE)
            )
          )
        })
        .catch(e => {
          console.error(e)
        })
    } else if (brandNow === 'local') {
      const defaultParams = {
        tt_from: 'pre_load_more',
        category: 'motor_car_live_complex',
        refer: 1,
        count: 12,
        impression_info: JSON.stringify({
          page_id: 'page_category',
          sub_tab: 'motor_car_live_complex',
        }),
        motor_feed_extra_params: JSON.stringify({
          new_feed: true,
          feed_type: 3,
          refresh_num: 7,
          loadmore_num: loadMoreCount,
          from_page_id: 'page_category',
          live_sub_tab: 'local',
          live_brand_id: '12',
          source_from: 'pc',
          pc_city: cityName,
        }),
        max_behot_time: lastCount,
        channel: 'web',
        device_platform: 'web',
        aid: 1839,
        user_id: web_id,
      }
      fetch(
        getUrl(
          `/motor/stream/api/news/feed/motor/v46/?${serializeObject(
            defaultParams
          )}`
        )
      )
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          setLoadMoreCount(loadMoreCount + 1)
          setLastCount(lastCount + (json.data?.length || 0))
          setShownData(
            shownData.concat(
              json.data.filter(filterShownData).map(mapShownData)
            )
          )
        })
        .catch(e => {
          console.error(e)
        })
    } else if (brandNow === 'bigv') {
      const defaultParams = {
        tt_from: 'pre_load_more',
        category: 'motor_car_live_complex',
        refer: 1,
        count: 9,
        impression_info: JSON.stringify({
          page_id: 'page_category',
          sub_tab: 'motor_car_live_complex',
        }),
        motor_feed_extra_params: JSON.stringify({
          new_feed: true,
          feed_type: 3,
          refresh_num: 1,
          loadmore_num: loadMoreCount,
          from_page_id: 'page_category',
          live_sub_tab: 'kol',
        }),
        max_behot_time: lastCount,
        channel: 'web',
        device_platform: 'web',
        aid: 1839,
        user_id: web_id,
      }
      fetch(
        getUrl(
          `/motor/stream/api/news/feed/motor/v46/?${serializeObject(
            defaultParams
          )}`
        )
      )
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          setLastCount(lastCount + (json.data?.length || 0))
          setLoadMoreCount(loadMoreCount + 1)
          setShownData(
            shownData.concat(
              json.data.filter(filterShownData).map(mapShownData)
            )
          )
        })
        .catch(e => {
          console.error(e)
        })
    } else if (!Number.isNaN(Number(brandNow))) {
      const defaultParams = {
        tt_from: 'pre_load_more',
        category: 'motor_car_live_complex',
        refer: 1,
        count: 9,
        impression_info: JSON.stringify({
          page_id: 'page_category',
          sub_tab: 'motor_car_live_complex',
        }),
        motor_feed_extra_params: JSON.stringify({
          new_feed: true,
          feed_type: 3,
          refresh_num: 5,
          loadmore_num: loadMoreCount,
          from_page_id: 'page_category',
          live_sub_tab: 'brand',
          live_brand_id: brandNow,
        }),
        max_behot_time: lastCount,
        channel: 'web',
        device_platform: 'web',
        aid: 1839,
        user_id: web_id,
      }
      fetch(
        getUrl(
          `/motor/stream/api/news/feed/motor/v46/?${serializeObject(
            defaultParams
          )}`
        )
      )
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          setLoadMoreCount(loadMoreCount + 1)
          setLastCount(lastCount + (json.data?.length || 0))
          setShownData(
            shownData.concat(
              json.data.filter(filterShownData).map(mapShownData)
            )
          )
        })
        .catch(e => {
          console.error(e)
        })
    }
  }

  return (
    <div className="live-show-wrapper">
      <h2 className="title">正在直播</h2>

      <div className="brand-line">
        {brands.map(e => (
          <BrandCard
            brandCard={e}
            key={e.brand_id}
            active={brandNow === e.brand_id}
            onClickTag={(bid: string) => {
              setBrandNow(bid)
            }}
          />
        ))}
      </div>

      <ScrollLoader
        loading={isLoading}
        loadMoreHandler={loadMoreHandler}
        end={false}
      >
        <div className="show-list">
          {shownData.length !== 0 ? (
            shownData.map((e, index) => (
              <Card
                {...e}
                key={e.unique_id_str + index}
                onClick={(user_id: string, unique_id_str: string) => {
                  sendTeaCommEvent('click_home_live_living_cell', {
                    sub_tab: brandNow,
                    anchor_id: user_id,
                    room_id: unique_id_str,
                  })
                }}
              />
            ))
          ) : !isLoading ? (
            <div className="no-data">
              <img src={empty_pic} />
              <span>
                当前直播已结束
                <br />
                再去看看其他的吧~
              </span>
            </div>
          ) : null}
        </div>
        {isLoading && <Loading />}
      </ScrollLoader>

      <style jsx>
        {`
          .live-show-wrapper {
            width: 100%;
            height: 100%;
          }
          .title {
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            flex: 0 0 auto;
          }
          .brand-line {
            margin-top: 14px;
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }

          .show-list {
            padding-top: 18px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }

          .no-data {
            width: 100%;
            height: 600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
          }

          .no-data img {
            width: 240px;
            height: 240px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 16px;
          }
          .no-data span {
            text-align: center;
            color: #333333;
            font-size: 18px;
          }
        `}
      </style>
    </div>
  )
}
