import React, { ReactNode } from 'react'
import Swiper from 'react-id-swiper'
import get from 'lodash/get'
import 'lib/swiper.less'
import { sendTeaCommEvent } from 'lib/tea-analyze'
import { IBannerList } from 'Pages/home/banner'

export interface SwiperProps {
  frameTimes: number
  imgArray?: string[]
  children?: ReactNode
  setSlideIndex?: (x: number) => void
  onShowSlide?: (x: number) => void
  onClickSlide?: (x: number) => void
}

const left_ic_img = require('static/left-ic.svg')
const default_img = require('static/default2.png')
const LiveSwiper = (props: SwiperProps) => {
  const {
    frameTimes,
    children,
    setSlideIndex,
    imgArray = [],
    onShowSlide,
    onClickSlide,
  } = props
  const params = {
    navigation: {
      nextEl: `.swiper-button-next.customized-swiper-button-next`,
      prevEl: `.swiper-button-prev.customized-swiper-button-prev`,
    },
    //height: 450,
    loop: true,
    autoplay: { disableOnInteraction: true, delay: 3000 },
    on: {
      slideChangeTransitionStart() {
        const activeIndex =
          (get(this, 'activeIndex', 1) - 1 + frameTimes) % frameTimes
        setSlideIndex && setSlideIndex(activeIndex)
        onShowSlide && onShowSlide(activeIndex)
      },
    },
  }
  return (
    <>
      {imgArray?.length > 1 ? (
        <Swiper {...params}>
          {imgArray.map((e, index) => (
            <img
              src={e}
              key={e}
              className="banner-img"
              onClick={e => {
                onClickSlide && onClickSlide(index)
              }}
            />
          ))}
        </Swiper>
      ) : (
        <img
          src={imgArray?.length === 1 ? imgArray[0] : default_img}
          className="banner-img"
          onClick={e => {
            onClickSlide && onClickSlide(0)
          }}
        />
      )}

      {children}

      <style jsx>
        {`

          .banner-img{
            object-fit: cover;
            object-position: top;
            width: 800px;
            height: 325px;
            background-color: rgba(0, 0, 0, 0.08);
            vertical-align: bottom;
          }

          :global(.noshow){
            display: none;
          }

          :global(.customized-swiper-button-prev, .customized-swiper-button-next){
            background-color: rgba(0, 0, 0, .4);
            opacity: 1;
            width: 32px;
            height: 48px;
            border-radius: 8px;
            transform: translateY(-50%);
            margin-top: 0;
          }
          :global( .customized-swiper-button-prev){
            border-radius: 0 8px 8px 0;
            
          }
          :global(.customized-swiper-button-next){
            border-radius: 8px 0 0 8px;
          }
          :global(.customized-swiper-button-prev:after, .customized-swiper-button-next::after) {
            width: 24px;
            height: 24px;
            margin-top: 0;
            transform: translateY(-50%);
            margin-left: -12px;
            background-image: url('${left_ic_img}');
          }

          :global(.customized-swiper-button-prev:hover, .customized-swiper-button-next:hover) {
            opacity: 1;
            background-color: #f5b330;
          }

          :global(.customized-swiper-button-prev:hover::after, .customized-swiper-button-next:hover::after ){
            background-image: url('${left_ic_img}');
          }

          :global(.customized-swiper-button-next:after, .customized-swiper-button-next:hover::after){
            transform: translateY(-50%) rotate(180deg); 
          }
        `}
      </style>
    </>
  )
}

interface BannerData extends IBannerList {
  user_info?: {
    user_id?: string
  }
}

const transit = (props: {
  bannerData: BannerData[]
  setSlideIndex: (x: number) => void
}) => {
  const { bannerData, setSlideIndex } = props
  return (
    <LiveSwiper
      imgArray={bannerData.map(e => e.img_url)}
      frameTimes={bannerData.length || 1}
      setSlideIndex={setSlideIndex}
      onShowSlide={(x: number) => {
        sendTeaCommEvent('show_home_live_top_banner', {
          rank: x,
          anchor_id: bannerData[x]?.user_info?.user_id || '',
          room_id: bannerData[x]?.gid,
        })
      }}
      onClickSlide={(x: number) => {
        sendTeaCommEvent('click_home_live_top_banner', {
          rank: x,
          anchor_id: bannerData[x]?.user_info?.user_id || '',
          room_id: bannerData[x]?.gid,
        })
      }}
    />
  )
}

export default transit
